// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "bootstrap/scss/functions";

// 2. Include any default variable overrides here

// Pulse 5.3.3
// Bootswatch Variables

@import "./variable-overrides.scss";

// Override Bootstrap variables
$btn-font-weight: 600;
$btn-padding-y-lg: 0.8125rem;
$btn-padding-x-lg: 2rem;
$btn-border-radius-lg: 0.5rem;

// Navbar overrides
$navbar-padding-y: 1rem * 0.75;
$navbar-light-brand-color: $white;

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";

// 4. Include any default map overrides here

$theme-colors: (
  "primary": $primary,
  "accent": $accent,
  // New theme color added (idea from Bootstrap Docs site)
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
);

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
);
// $spacers: (
//   0: 0,
//   1: $spacer * .25,
//   2: $spacer * .5,
//   3: $spacer,
//   4: $spacer * 1.5,
//   5: $spacer * 3,
//   6: $spacer * 6,
// );
// $btn-font-size-lg: $font-size-base;

// 5. Include remainder of required parts
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/helpers";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/card";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/offcanvas";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/helpers";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`

@import "bootstrap/scss/utilities/api";

// 8. Add additional custom code here

/*!
 * Bootstrap Docs - Customize Below
 */

// Load Bootstrap variables and mixins
// @import "bootstrap/scss/functions"; // already loaded above
// @import "bootstrap/scss/variables"; // already loaded above
// @import "bootstrap/scss/mixins"; // already loaded above

// fusv-disable
$enable-grid-classes: false;
$enable-cssgrid: true;
// fusv-enable
// @import "bootstrap/scss/grid"; // already loaded above

// Load docs components
@import "bootstrap/site/assets/scss/variables";
@import "bootstrap/site/assets/scss/navbar";
// @import "bootstrap/site/assets/scss/search";
// @import "bootstrap/site/assets/scss/masthead";
// @import "bootstrap/site/assets/scss/ads";
@import "bootstrap/site/assets/scss/content";
// @import "bootstrap/site/assets/scss/skippy";  // skip to main content link
@import "bootstrap/site/assets/scss/sidebar";
@import "bootstrap/site/assets/scss/layout";
@import "bootstrap/site/assets/scss/toc"; // Table of Contents (right aside)
@import "bootstrap/site/assets/scss/footer";
// @import "bootstrap/site/assets/scss/component-examples";
// @import "bootstrap/site/assets/scss/buttons";
@import "bootstrap/site/assets/scss/callouts";
@import "bootstrap/site/assets/scss/brand";
@import "bootstrap/site/assets/scss/colors";
// @import "bootstrap/site/assets/scss/clipboard-js";
@import "bootstrap/site/assets/scss/placeholder-img";
@import "bootstrap/site/assets/scss/scrolling";

// Load docs dependencies
// @import "bootstrap/site/assets/scss/syntax";
@import "bootstrap/site/assets/scss/anchor";

@import "bootswatch/dist/pulse/bootswatch.scss";
