.bd-navbar {
  padding: .75rem 0;
  background-color: transparent;
  box-shadow: 0 .5rem 1rem rgba($black, .15), inset 0 -1px 0 rgba($white, .15);

  &::after {
    position: absolute;
    inset: 0;
    z-index: -1;
    display: block;
    content: "";
    background-image: linear-gradient(rgba(var(--bd-violet-rgb), 1), rgba(var(--bd-violet-rgb), .95));
  }

  .bd-navbar-toggle {
    @include media-breakpoint-down(lg) {
      width: 4.25rem;
    }
  }

  .navbar-toggler {
    padding: 0;
    margin-right: -.5rem;
    border: 0;

    &:first-child {
      margin-left: -.5rem;
    }

    .bi {
      width: 1.5rem;
      height: 1.5rem;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .navbar-brand {
    color: $white;
    @include transition(transform .2s ease-in-out);

    &:hover {
      transform: rotate(-5deg) scale(1.1);
    }
  }

  .navbar-toggler,
  .nav-link {
    padding-right: $spacer * .25;
    padding-left: $spacer * .25;
    color: rgba($white, .85);

    &:hover,
    &:focus {
      color: $white;
    }

    &.active {
      font-weight: 600;
      color: $white;
    }
  }

  .navbar-nav-svg {
    display: inline-block;
    vertical-align: -.125rem;
  }

  .offcanvas-lg {
    background-color: var(--bd-violet-bg);
    border-left: 0;

    @include media-breakpoint-down(lg) {
      box-shadow: var(--bs-box-shadow-lg);
    }
  }

  .dropdown-toggle {
    &:focus:not(:focus-visible) {
      outline: 0;
    }
  }

  .dropdown-menu {
    --bs-dropdown-min-width: 12rem;
    --bs-dropdown-padding-x: .25rem;
    --bs-dropdown-padding-y: .25rem;
    --bs-dropdown-link-hover-bg: rgba(var(--bd-violet-rgb), .1);
    --bs-dropdown-link-active-bg: rgba(var(--bd-violet-rgb), 1);
    @include rfs(.875rem, --bs-dropdown-font-size);
    @include font-size(.875rem);
    @include border-radius(.5rem);
    box-shadow: $dropdown-box-shadow;

    li + li {
      margin-top: .125rem;
    }

    .dropdown-item {
      @include border-radius(.25rem);

      &:active {
        .bi {
          color: inherit !important; // stylelint-disable-line declaration-no-important
        }
      }
    }

    .active {
      font-weight: 600;

      .bi {
        display: block !important; // stylelint-disable-line declaration-no-important
      }
    }
  }

  .dropdown-menu-end {
    --bs-dropdown-min-width: 8rem;
  }
}

@include color-mode(dark) {
  .bd-navbar {
    box-shadow: 0 .5rem 1rem rgba($black, .15), inset 0 -1px 0 rgba($white, .15);
  }
}
