//
// Brand guidelines
//

// Logo series wrapper
.bd-brand-logos {
  color: $bd-violet;

  .inverse {
    color: $white;
    background-color: $bd-violet;
  }
}

// Individual items
.bd-brand-item {
  + .bd-brand-item {
    border-top: 1px solid var(--bs-border-color);
  }

  @include media-breakpoint-up(md) {
    + .bd-brand-item {
      border-top: 0;
      border-left: 1px solid var(--bs-border-color);
    }
  }
}


//
// Color swatches
//

.color-swatches {
  margin: 0 -5px;

  // Docs colors
  .bd-purple {
    background-color: $bd-purple;
  }
  .bd-purple-light {
    background-color: $bd-purple-light;
  }
  .bd-purple-lighter {
    background-color: #e5e1ea;
  }
  .bd-gray {
    background-color: #f9f9f9;
  }
}

.color-swatch {
  width: 4rem;
  height: 4rem;

  @include media-breakpoint-up(md) {
    width: 6rem;
    height: 6rem;
  }
}
