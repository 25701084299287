.bd-sidebar {
  @include media-breakpoint-up(lg) {
    position: sticky;
    top: 5rem;
    // Override collapse behaviors
    // stylelint-disable-next-line declaration-no-important
    display: block !important;
    height: subtract(100vh, 6rem);
    // Prevent focus styles to be cut off:
    padding-left: .25rem;
    margin-left: -.25rem;
    overflow-y: auto;
  }

  @include media-breakpoint-down(lg) {
    .offcanvas-lg {
      border-right-color: var(--bs-border-color);
      box-shadow: var(--bs-box-shadow-lg);
    }
  }
}

.bd-links-heading {
  color: var(--bs-emphasis-color);
}

.bd-links-nav {
  @include media-breakpoint-down(lg) {
    font-size: .875rem;
  }

  @include media-breakpoint-between(xs, lg) {
    column-count: 2;
    column-gap: 1.5rem;

    .bd-links-group {
      break-inside: avoid;
    }

    .bd-links-span-all {
      column-span: all;
    }
  }
}

.bd-links-link {
  padding: .1875rem .5rem;
  margin-top: .125rem;
  margin-left: 1.125rem;
  color: var(--bs-body-color);
  text-decoration: if($link-decoration == none, null, none);

  &:hover,
  &:focus,
  &.active {
    color: var(--bs-emphasis-color);
    text-decoration: if($link-hover-decoration == underline, none, null);
    background-color: var(--bd-sidebar-link-bg);
  }

  &.active {
    font-weight: 600;
  }
}
