// When navigating with the keyboard, prevent focus from landing behind the sticky header

main {
  a,
  button,
  input,
  select,
  textarea,
  h2,
  h3,
  h4,
  [tabindex="0"] {
    scroll-margin-top: 80px;
    scroll-margin-bottom: 100px;
  }
}
